<template>
  <div class="my-page-interest my-page">
    <div class="top">
      <ul class="nav">
        <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
          <router-link :to="m.path" :class="[$route.path === m.path ? 'active btn-bordered-point': 'btn-light']" class="btn">{{ m.title }}</router-link>
        </li>
      </ul>
    </div>
    <div class="content">
      <template v-if="state.loaded">
        <ul class="tight clearfix" v-if="state.projects.length">
          <li v-for="p in state.projects" :id="component.name + p.projectType + p.projectSeq" :key="p.projectType + p.projectSeq">
            <Card
                :projectSeq="p.projectSeq"
                :projectType="['R', 'M'].includes(p.projectType) ? 'reward' : 'invest'"
                :link="`/${['R', 'M'].includes(p.projectType) ? 'reward' : 'invest'}/${p.projectSeq}`"
                :thumbFilePath="p.thumbFilePath"
                :amount="p.totalAmount"
                :projectName="p.projectName"
                :progressOrder="p.progressOrder"
                :simpleText="p.simpleText"
                :projectCate="p.projectCateName"
                :fundingType="p.fundingType"
                :count="p.investorCount"
                :percent="p.investRate"
                :dday="Number(p.dday)"
                :builderSeq="p.builderSeq"
                :builderName="p.builderName"
                :builderImageUrl="p.builderImageUrl"
                :successFailName="p.successFailName"
                :stockTypeText="p.stockTypeText"
                :rewardFlag="p.rewardFlag"
                :incomeDeductionFlag="p.incomeDeductionFlag"
                :mainImagePosition="p.mainImagePosition"
                :interest="true"
            />
          </li>
        </ul>
        <NoData v-else/>
      </template>
      <ul class="tight clearfix" v-else>
        <li v-for="i in 2" :key="i">
          <Card :skeleton="true" builderName="wait a moment" projectName="wait" projectCate="wait"
                simpleText="wait" fundingType="A" :progressOrder="1" :count="100" :amount="100"
                :percent="0"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Card from "@/components/Card.vue";
import NoData from "@/components/NoData";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageMyPageInterest";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, Card},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "관심 프로젝트");
      load(true);
    });

    const state = reactive({
      loaded: false,
      menus: [
        {name: "total", title: "전체", path: "/mypage/interest"},
        {name: "reward", title: "후원형", path: "/mypage/interest/reward"},
        {name: "invest", title: "증권형", path: "/mypage/interest/invest"},
      ],
      projects: []
    });

    const fadeOut = (id) => {
      document.getElementById(id).style.opacity = "0";
    };

    const load = (init) => {
      if (init) {
        state.loaded = false;
      }

      const args = {};

      if (router.app.$route.path.endsWith("/reward")) {
        args.projectType = "reward";
      } else if (router.app.$route.path.endsWith("/invest")) {
        args.projectType = "invest";
      }

      http.get("/api/interests/projects", args).then(({data}) => {
        if (init) {
          state.loaded = true;
        }

        state.projects = data.body;
      }).catch(httpError());
    };

    return {component, state, fadeOut, load};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-interest {
  margin-bottom: $px-25;

  .content {
    ul {
      margin: 0 $px-15 0 $px-15;

      li {
        float: left;
        width: calc(100% / 2);
        padding: 0 $px15 $px35 $px15;
        opacity: 1;
        transition: opacity 1s;
      }
    }
  }

  @media(max-width: 1199px) {
    .content ul li {
      width: calc(100% / 2);
    }
  }

  @media(max-width: 767px) {
    .content ul li {
      width: 100%;
    }
  }
}
</style>